import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import { useLocation } from '@reach/router';

const Head = ({ title, description, shareImage, noIndex }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `);

  const { pathname } = useLocation();

  const pageTitle = `${title} - ${data.site.siteMetadata.title}`;
  const pageDescription = description || data.site.siteMetadata.description;
  const cardImage =
    shareImage || 'https://hollingdeanparking.com/share-card.jpg';
  const pageURL = `https://hollingdeanparking.com${pathname}`;

  return (
    <Helmet
      htmlAttributes={{ lang: 'en' }}
      title={pageTitle}
      meta={[
        { name: 'description', content: pageDescription },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:domain', content: 'hollingdeanparking.com' },
        { name: 'twitter:title', content: pageTitle },
        { name: 'twitter:description', content: pageDescription },
        { name: 'twitter:image', content: cardImage },
        { name: 'twitter:image:alt', content: pageDescription },
        {
          property: 'og:site_name',
          content: `Hollingdean Parking: ${data.site.siteMetadata.description}`,
        },

        { property: 'og:type', content: 'website' },
        { property: 'og:locale', content: 'en_GB' },
        { property: 'og:locale:alternative', content: 'en_US' },
        { property: 'og:url', content: pageURL },
        { property: 'og:title', content: pageTitle },
        { property: 'og:description', content: pageDescription },
        { property: 'og:image', content: cardImage },
        { property: 'og:image:secure_url', content: cardImage },
        { property: 'og:image:alt', content: pageDescription },
        { property: 'og:image:width', content: 1200 },
        { property: 'og:image:height', content: 630 },
        {
          name: 'robots',
          content: noIndex ? 'noindex, follow' : 'index, follow',
        },
      ]}
    />
  );
};

export default Head;
