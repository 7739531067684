import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';

import styles from './header.module.scss';

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <header className={styles.header}>
      <h1>
        <Link className={styles.title} to="/">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1000 1000"
            xmlSpace="preserve"
          >
            <path d="m988.5 683-8.9-26c-4.9-14.4-20.6-26-35-26h-30.3c-18.9-53.8-38.6-101.5-45.1-116.2-11.9-27-49.9-32.8-70.9-38.7 0 0-35-10.7-151.6-10.7s-151.6 10.7-151.6 10.7c-24.5 8.3-58.6 13.6-72.1 39.9-5.4 10.5-25.4 59.6-44.6 115h-30c-14.4 0-30 11.6-35 26l-8.9 26c-4.9 14.4 2.7 31.1 17.1 31.1h30c-8 23.4-13.4 34.2-13.4 34.2-2.2 3.8-3.8 21.4-3.8 26.2v194.8c0 14.4 11.6 26 26 26h72.3c14.4 0 26-11.6 26-26v-36.5h375.8v36.5c0 14.4 11.6 26 26 26H933c14.4 0 26-11.6 26-26V774.4c0-4.7-1.6-22.4-3.8-26.2 0 0-5.5-10.8-13.6-34.2h29.7c14.5.1 22.1-16.7 17.2-31zM493.2 839.1h-34.6c-30.9 0-61.8-4.5-61.8-26v-26c0-25.1 13.3-26 27.7-26 14 0 41.8 13 60.1 21.9 20 9.8 42.3 17.9 42.3 34.2.1 15-7.6 21.9-33.7 21.9zM421 658.7c18.3-72.5 38.1-99.7 42.5-111.1 3.5-8.3 6.9-33.9 183.3-33.9s180.5 29.3 181 29.6c5.4 15.5 27 48.6 44.3 115.4 0 0-74.5 24.2-225.2 24.2S421 658.7 421 658.7zm475.7 154.4c0 21.5-30.9 26-61.8 26h-34.6c-26 0-33.7-6.9-33.7-21.9 0-16.3 22.3-24.4 42.3-34.2 18.3-9 46.1-21.9 60.1-21.9 14.4 0 27.7.9 27.7 26v26zM611.3 346.4c-16-45.3-32.4-86.1-41.1-106.8 8.8 20.8 25.2 61.5 41.1 106.8z" />
            <path d="M348.5 589.1h.5c15.4-42.4 30.8-80.3 36.9-92.3 19.3-37.5 58-49 83.6-56.6 4.3-1.3 8.4-2.5 12.3-3.8 8-2.5 48.1-13 165-13h2.6c-.3-.9-.5-1.5-.9-2.4h29.7c14.4 0 22-16.7 17.1-31.1l-8.9-26c-4.9-14.4-20.6-26-35-26h-30.3c-18.9-53.8-38.6-101.5-45.1-116.2-11.9-27-49.9-32.8-70.9-38.7 0 0-35-10.7-151.6-10.7S201.9 183 201.9 183c-24.5 8.3-58.5 13.6-72.1 39.9-5.4 10.5-25.4 59.6-44.6 115H55.4c-14.4 0-30 11.6-35 26l-8.9 26C6.6 404.2 14.2 421 28.6 421h30c-8 23.4-13.4 34.2-13.4 34.2-2.2 3.8-3.8 21.4-3.8 26.2v194.8c0 14.4 11.6 26 26 26h72.3c14.4 0 26-11.6 26-26v-36.5h110.1c12-29.4 41.6-50.6 72.7-50.6zm-178-334.5c3.5-8.3 6.9-33.9 183.3-33.9 176.3 0 180.5 29.3 181 29.6 5.4 15.5 27 48.6 44.3 115.4 0 0-74.5 24.2-225.2 24.2-150.9 0-225.9-24.3-225.9-24.3 18.2-72.4 38.1-99.6 42.5-111zm29.7 291.5h-34.5c-30.9 0-61.8-4.5-61.8-26v-26c0-25.1 13.3-26 27.7-26 14 0 41.8 13 60.1 21.9 20 9.8 42.3 17.9 42.3 34.2-.1 14.9-7.8 21.9-33.8 21.9z" />
            <path d="M463.5 87.1c3.5-8.3 6.9-33.9 183.3-33.9 176.3 0 180.5 29.3 181 29.6 5.4 15.5 27 48.6 44.3 115.4 0 0-74.5 24.2-225.2 24.2-8.9 0-16.7-.3-25.1-.5 7.1 17.1 17.5 43.1 28.9 74.1h.9c32.4 0 63.8 22.9 74.5 54.3l8.9 26c5.7 16.5 4.7 34.2-2 49.6 51.4 3.2 72.8 8.7 77.6 10.1l7.8 2c26.5 6.7 70.7 17.9 89.2 59.9 2.7 6.2 8.2 19.4 15.2 36.8H933c14.4 0 26-11.6 26-26V313.9c0-4.7-1.6-22.4-3.8-26.2 0 0-5.5-10.8-13.6-34.2h29.7c14.4 0 22-16.7 17.1-31.1l-9-26c-4.9-14.4-20.6-26-35-26h-30.3C895.2 116.6 875.5 68.9 869 54.2c-11.9-27-49.9-32.8-70.9-38.7 0 0-35-10.7-151.6-10.7S494.9 15.5 494.9 15.5c-24.5 8.3-58.6 13.6-72.1 39.9-4 7.9-16.3 37.6-30.4 75.4 19.2.4 35 1.2 49.2 2.1 10.7-25.7 19.3-38.8 21.9-45.8zm433.2 239.4v26c0 21.5-30.9 26-61.8 26h-34.6c-26 0-33.7-6.9-33.7-21.9 0-16.2 22.4-24.4 42.3-34.2 18.3-9 46.1-21.9 60.1-21.9 14.3 0 27.7.9 27.7 26z" />
          </svg>
          {data.site.siteMetadata.title}
        </Link>
      </h1>
      <nav>
        <ul className={styles.navList}>
          <li>
            <Link
              className={styles.navItem}
              activeClassName={styles.activeNavItem}
              partiallyActive
              to="/"
            >
              Home
            </Link>
          </li>
          {/* <li>
            <Link
              className={styles.navItem}
              activeClassName={styles.activeNavItem}
              partiallyActive
              to="/about/"
            >
              About
            </Link>
  </li> */}
          <li>
            <Link
              className={styles.navItem}
              activeClassName={styles.activeNavItem}
              partiallyActive
              to="/gallery/"
            >
              Gallery
            </Link>
          </li>
          {/* <li>
            <Link
              className={styles.navItem}
              activeClassName={styles.activeNavItem}
              partiallyActive
              to="/contact/"
            >
              Contact
            </Link>
</li>*/}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
